import "./sozlesme.css";

export default function Sozlesme() {
  return (
    <>
      <div className="icerik" style={{ backgroundColor: "#FFF" }}>
        <h1 style={{ color: "red" }}>PratikPet.Com</h1>

        <h3>
          <b>Kullanıcı Sözleşmesi</b>
        </h3>
        <br />

        <p>
          <b>Madde 1. Taraflar</b>
          <br />
          <br />
          Taraflar İşbu Pet Shop Programı Kullanıcı Sözleşmesi (“Sözleşme”),
          <br />
          <br />
          a) PratikPet.com internet sitesinin faaliyetlerini yürüten Pratik
          Yönet Bilişim Sanayi ve Ticaret Limited Şirketi. Paşa Alanı
          Mah.113.Sk.No:1 A Karesi / Balıkesir.(“Pratik Yönet”) ile <br />
          <br />
          b) PratikPet.com internet sitesine üye olan ve bu Sözleşmeyi onaylayan
          kullanıcı ("Kişi ,Kurum veya Şirket ki bu sözleşmede Müşteri olarak
          adlandırılacaktır") arasında akdedilmiştir
        </p>

        <p>
          <b>Madde 2. Sözleşmenin Konusu</b>
          <br />
          <br />
          Taraflar İşbu Pet Shop Programı Kullanıcı Sözleşmesi (“Sözleşme”),
          <br />
          İşbu sözleşmenin konusu PratikPet internet sitesi üzerinden
          kullanımına izin verilen Pet Shop Programının müşteriye sunulması ,
          müşterinin programı kullanımı ,müşteri ve müşterinin izin verdiği
          çalışanlarının (Çalışanlar) İnternet sitesine yükleyecekleri veriler ,
          bilgiler ve belgelerin (içerik) ilişkin koşul ve şartların neler
          olduğunun belirlenmesi, tarafların hak ve yükümlülüklerinin
          saptanmasıdır.
        </p>

        <p>
          <b>Madde 3. Fikri Mülkiyet Hakları</b>
          <br />
          <br />
          3-1. Müşteri internet sitesini İş bu sözleşmede belirtilen şekilde
          kullanacak olup, bu hakkı ödünç veremez, sahip olduğu lisans
          sözleşmesini hiç bir şekilde kişi kurum veya şirketlere kiraya
          veremez, satamaz ve müşteri çalışanları dışında ücretli veya ücretsiz
          kullandıramaz. <br />
          <br />
          3-2. Pratik Yönet tarafından internet sitesinde kullanılan bilgilerin
          , içeriklerin ve/veya yazılımın kopyalanması çoğaltılması ve/veya bu
          sözleşmede belirtilen faydalanmanın ötesinde kullanılması,
          dağıtılması, işlenmesi kesinlikle yasaktır. <br />
          <br />
          3-3. Müşterinin PratikPet ve Pratik Yönet sistemlerine zarar
          verebilecek eylemlerde bulunması , bu sistemlerin güvenliğini tehdit
          edebilecek ve PratikPet müşterilerinin güvenliklerini tehdit
          edebilecek veya diğer müşterilerin kullanımlarını riske atabilecek
          davranışlarda ve eylemlerde bulunmaları kesinlikle yasaktır. <br />
          <br />
          3-4. Madde 3-3 te belirtilen PratikPet İnternet sitesi ve bu internet
          sitesine ait diğer yazılımların çalışmasını veya diğer kullanıcıların
          internet sitesinden faydalanmasını engelleyebilecek herhangi bir
          girişimde bulunması, <br />
          <br />
          - bu sonuçları verecek şekilde sisteme veya siteye orantısız yük
          bindirmesi, <br />
          - internet sitesi’nin çalışmasını engelleyecek yazılımlar kullanması,
          kullanmaya çalışması <br />
          - tersine mühendislik yapması, saldırılar düzenlemesi, meşgul etmesi
          veya müdahale etmesi, <br />
          - sitenin kaynak kodlarına veya PratikPet’in sistemlerine yetkisiz bir
          şekilde erişmesi, <br />
          - bu bilgileri kopyalaması, silmesi, değiştirmesi ya da bu yönde
          denemeler yapması, <br />
          - her türlü yazılım, donanım ve sunucuların çalışmasını aksatması,
          bozulmasına yol açması, <br />
          - PratikPet sunucularına erişim sağlamaya çalışılması <br />
          <br />
          kesinlikle yasaktır. Müşteri söz konusu yasaklara Çalışanlar’ın da
          uymasını sağlamakla yükümlü olup Çalışanlar’ın bu kapsamdaki her türlü
          aykırılığından müteselsilen sorumlu olacaktır.
        </p>

        <p>
          <b>Madde 4. Tarafların Hak ve Yükümlülükleri</b>
          <br />
          <br />
          Taraflar İşbu Pet Shop Programı Kullanıcı Sözleşmesi (“Sözleşme”),
          <br />
          <br />
          4-1. Müşteri PratikPet ‘e üye olurken vereceği bilgilerin
          doğruluğundan sorumludur. Sonradan değişen bilgilerinin
          güncellenmesinden sorumludur. Bu bilgilerin doğru olmaması veya
          güncellenmemesi nedeniyle siteye ulaşamamasından Pratik Yönet sorumlu
          değildir. <br />
          <br />
          4-2. Pratik Yönet internet sitesine erişim hakkını belirlenen ücretler
          karşılığında müşteriye sağlamakla sorumludur. Müşteri deneme süresi
          sonunda ilgili ücretleri ödememesi durumunda internet sitesinden ve
          programdan faydalanamayacağını kabul eder.
          <br />
          <br />
          4-3. Pratik Yönet müşterinin programa ve siteye yüklediği bilgilerin
          düzenli aralıklarla yedeklenmesinden sorumludur. <br />
          <br />
          4-4. Müşteri ve çalışanları programı kullanmak için sahip olduğu
          şifreyi başka kişi, kuruluş veya şirketlere veremez. Müşteri ve
          çalışanları şifrelerini kullanma hakkı ve şifrelerinin güvenliğini ve
          gizliliğini sağlama yükümlülüğü bizzat kendilerine aittir.
          Şifrelerinin yetkili olmayan kişiler tarafından kullanılması
          neticesinde müşteri, kendisinin, müşterinin çalışanları veya üçüncü
          kişilerin uğrayacağı zararlardan sorumlu olacağını , PratikPet ‘in
          ve/veya Pratik Yönet’in sorumlu olmayacağını peşinen kabul eder.
          Müşteri, çalışanlarının program ve internet sitesi üzerindeki
          faaliyetlerinden bizzat sorumludur.
          <br />
          <br />
          4-5. Pratik Yönet yalnızca programın ve sitenin alt yapısını
          sağlamakta olup programda oluşturulan veya programa yüklenen bilgi ve
          belgelere ilişkin yetki ve sorumluluk taşımamaktadır. Müşteri program
          da oluşturulacak veya yüklenecek bilgi ve belgelerin içeriklerinin
          doğru olduğunu , hukuka ve kanunlara uygun olduğunu, ilgili
          mevzuatlara uygun olduğunu kabul eder. Programa yüklenen veya
          programda oluşturulan her türlü bilgi, veri ve içeriğinin sorumluluğu
          müşteriye aittir. <br />
          <br />
          4-6 Müşteri, programa kişisel verileri yükleyecek Çalışanlar
          bakımından 6698 sayılı Kişisel Verilerin Korunması Kanunu kapsamında
          veri sorumlusu sıfatına haiz olduğunu, kişisel verilerin programa
          yüklenmesi ve veri işleyen sıfatını haiz Pratik Yönet tarafından bu
          verilerin işlenmesi de dahil olmak üzere bu kapsamda Çalışanlar’dan
          gerekli onayların alınması, Çalışanlar’a gerekli bilgilendirmelerin
          yapılması ve ilgili mevzuat kapsamındaki yükümlülüklerin yerine
          getirilmesinin münhasıran kendi sorumluluğunda olduğunu, bu kapsamda
          Pratik Yönet’in maruz kalabileceği her türlü talep ve zararın
          kendisine rücu edileceğini kabul eder <br />
          <br />
          4-7 Pratik Yönet , Müşteri’nin bilgilerini yasal bir zorunluluk olarak
          istendiğinde, yasal gereklere uygun hareket etmek veya Pratik Yönet’e
          tebliğ edilen yasal işlemlere uymak ve Program ve PratikPet’in
          haklarını ve mülkiyetini korumak ve savunmak için gerekli olduğuna iyi
          niyetle kanaat getirdiği hallerde açıklayabilir. <br />
          <br />
          4-8 Müşteri‘nin programı kullanmayı bıraktığı durumlarda müşterinin
          programa yüklediği bilgi ve belgelerin kopyaları Pratik Yönet
          tarafından temin edilecektir. <br />
          <br />
          4-9 Prati Yönet programda yenilikler yapma, geliştirmeler yapma, yeni
          sürüm çıkartma , güncellemeler yapma, yeni özellikler ekleme veya
          çıkarma hakkına sahiptir. <br />
          <br />
          4-10 Pratik Yönet mevcut imkanlar dahilinde virüs ve her türlü kötü
          yazılımlar için tedbir almıştır. Bununla birlikte güvenliğin tam
          olarak sağlanabilmesi için müşterinin de kendi virüs koruma programını
          temin etmesi gerekmektedir. <br />
          <br />
          4-11 Müşteri programdan faydalanmak için gerçekleştireceği tüm
          işlemlerde, bu sözleşmeye, kanun ve yükümlülüklere ve Pratik Yönet in
          belirlediği koşullara uymayı taahhüt eder. Müşteri, çalışanlarında
          programı kullanırken bu sözleşme ye, ilgili kanunlara ve dürüstlük
          ilkesine uymasından sorumludur. <br />
          <br />
          4-12 Program’ın kullanımından doğan her türlü yasal, idari ve cezai
          sorumluluk Müşteri ’ye aittir. Pratik Yönet Müşteri’nin veya
          Çalışanlar’ın Program üzerinde gerçekleştirdiği faaliyetler ve/veya
          işbu Sözleşme ve yasaya aykırı eylemleri neticesinde Müşteri,
          Çalışanlar ve üçüncü kişilerin uğrayabilecekleri zararlardan doğrudan
          ve/veya dolaylı olarak hiçbir şekilde sorumlu tutulamaz. Üçüncü
          kişilerden bu kapsamda gelecek her türlü talep ile Müşteri’nin ve/veya
          Çalışanlar’ın eylemlerinden kaynaklanan zararlar ilk talepte ödenmek
          üzere Müşteri’ye rücu edilecektir.
        </p>

        <p>
          <b>Madde 5. Sorumlulukların Sınırlandırılması</b>
          <br />
          <br />
          5-1 Müşteri programın kullanımından, program a yüklenecek belge ve
          bilgilerin içeriğinden sorumludur. Müşteri program kullanımı, müşteri
          tarafından verilen hizmet ,belgeler ve bilgilerin içeriği ,fikri
          mülkiyet ihlalleri gibi nedenlerden Çalışanlar, 3.şahıslar ve kurumlar
          tarafından iletilebilecek her türlü iddia ve talepten PratikPet ve
          Pratik Yönet’i beri kıldığını kabul eder. <br />
          <br />
          5-2 Müşteri, Program üzerinden sunulan içeriklere erişim ve bunların
          kalitesinin büyük ölçüde ilgili internet servis sağlayıcısından temin
          edilen hizmetin kalitesine dayandığını ve söz konusu hizmet
          kalitesinden kaynaklı sorunlarda Pratik Yönet’in herhangi bir
          sorumluluğunun bulunmadığını, Pratik Yönet’in Programı’nın herhangi
          bir cihaz ya da web tarayıcı ile uyumlu olduğuna dair bir taahhütte
          bulunmadığını, Program’ın işleyişinin kusurdan ari olmadığını ve zaman
          zaman teknik aksaklıklar veya erişim engelleriyle karşılaşabileceğini
          bildiğini kabul ve beyan eder. <br />
          <br />
          5-3 Hizmetler olduğu şekliyle sunulmaktadır. Pratik Yönet,
          tedarikçileri ve lisans sahipleri sözleşmeye konu hizmetlerin amaca
          uygunluğu, satılabilirlik ve ihlalsizlik de dahil olmak ve bunlarla da
          sınırlı kalmamak üzere, zımni dahi olsa hiçbir garanti vermezler.
          <br />
          <br />
          Pratik Yönet, tedarikçileri ve temsilcileri, burada sayılanlarla
          sınırlı olmamakla birlikte;
          <br />
          <br />
          - Hizmetlerin hatasız olduğunu,
          <br />
          - Hizmetlere ulaşımın kesintisiz ve devamlı olduğunu,
          <br />
          - Hizmetlerin sizin özel talep ve ihtiyaçlarınızı karşılayacağını,
          <br />
          - Hizmetlerin ve ürünlerin kalitesinin beklentilerinizi
          karşılayacağını,
          <br />
          - Ve Hizmetlerdeki her hatanın düzeltileceğini
          <br />
          garanti etmez.
          <br />
          <br />
          Pratik Yönet, tedarikçileri ve temsilcileri her hangi bir zamanda
          habersiz olarak bakım, onarım, güncelleme çalışmaları ya da başka bir
          nedenden dolayı Hizmetleri kullanımınızı tamamen ya da kısmi olarak
          durdurabilir veya sınırlandırabilir. Bu sırada oluşabilecek her hangi
          bir kesinti ya da bilgi kaybından Pratik Yönet sorumlu değildir.
          <br />
          <br />
          Müşteri’nin Program’ı kullanması, kullanmaması ya da yanlış kullanması
          veya Sözleşme ’de belirtilen taahhüt ve yükümlülüklerini yerine
          getirmemesi sebebiyle ortaya çıkacak doğrudan veya dolaylı
          zararlardan, her türlü kar kaybı, veri kaybı, iş kaybı, itibar kaybı,
          beklenen tasarrufun kaybı, işin kesintiye uğraması gibi her türlü
          maddi, manevi ve mali zararlardan, üçüncü bir tarafça yapılabilecek
          tazminat taleplerinden; tüm donanım, sistem yazılımı/diğer yazılımlar
          ve ağ ilişkili işlevden ve bu nedenle ortaya çıkacak arızalardan,
          iletişim ağı (network), internet bağlantısı, bağlantı hatalarından
          Program üzerinde Müşteri, Çalışanlar veya üçüncü kişiler tarafından
          gerçekleştirilecek her türlü değişiklik, güncelleme ve benzeri
          çalışmalardan , voltaj dalgalanmalarından, batarya ve elektrik
          kesilmelerinden, virüs bulaşmasından ve benzeri çevresel faktörlerden
          doğacak zararlardan sorumlu değildir.
        </p>

        <p>
          <b>Madde 6. Sözleşmenin Feshi</b>
          <br />
          <br />
          6-1. İşbu Sözleşme Müşteri’nin üyeliğini iptal etmesi veya Pratik
          Yönet tarafından üyeliğinin iptal edilmesine kadar yürürlükte
          kalacaktır. Taraflardan birinin Sözleşme’nin herhangi bir hükmünü
          yahut ilgili mevzuatı ihlal etmesi halleri başta olmak üzere ya da
          herhangi bir neden göstermeksizin diğer taraf Sözleşme’yi tek taraflı
          olarak feshedebilecektir. İşbu Sözleşme’nin herhangi bir nedenle feshi
          ya da sona ermesi halinde Müşteri tarafından peşin olarak ödenmiş
          bedellerin iadesi söz konusu olmayacaktır.
          <br />
          <br />
          6-2. Müşteri sözleşmenin feshinden itibaren 1 ay içinde program da
          sakladığı veya programda oluşturduğu bilgi, belge ve bunlarla ilgili
          kayıtları talep edebilir. Müşterinin böyle bir talepte bulunmaması
          neticesinde kanunlar ve mevzuatta belirlenen tarihin geçmesine
          müteakip Pratik Yönet bu bilgi belge ve kayıtları silebilir. Söz
          konusu kayıtların silinmesinden sorumlu tutulamaz.
        </p>

        <p>
          <b>Madde 7. Muhtelif Hükümler</b>
          <br />
          <br />
          7-1. Mücbir sebep sayılan ( savaş ,doğal afet ,grev , lokavt ,kötü
          hava koşulları ,telekomünikasyon altyapısından kaynaklanan problemler
          ,elektrik kesintisi gibi ve fakat bunlarla sınırlı kalmamak kaydıyla )
          durumlarda sözleşmeye taraf olanlar bu sözleşmede belirlenen
          hükümlerin bir kısmını yada tamamını geç yada eksik ifa etmelerinden
          yada etmemelerinden dolayı sorumlu tutulamaz.
          <br />
          <br />
          7-2 .Pratik Yönet bu sözleşme ile ilgili güncellemeleri ve değişikleri
          e-posta yoluyla Müşteriye bildirmek yada program içinde duyurmak
          zorundadır. Bu şekilde yapılan duyuru müşteri için hüküm kabul edilir.
          Müşteri değişiklik ve güncellemeler konusunda olur vermez ise tek
          taraflı ve tazminatsız olarak sözleşmeyi fesih edebilir.
          <br />
          <br />
          7-3. Taraflar, taraflara ait tüm bilgisayar kayıtlarının tek ve gerçek
          münhasır delil olarak, HMK madde 193′e uygun şekilde esas alınacağını
          ve söz konusu kayıtların bir delil sözleşmesi teşkil ettiği hususunu
          kabul ve beyan eder.
          <br />
          <br />
          7-4. Pratik Yönet bu hizmeti müşterilerine haber vermeksizin istediği
          ülkeden kullandırabilir. Böylesi bir durumda doğrudan o ülkenin kanun
          ve mevzuatlarına bağlı kalacaktır.
          <br />
          <br />
          7-5. İşbu sözleşmeye ilişkin ihtilaflarda Balıkesir Mahkemeleri ve
          İcra Daireleri yetkilidir.
        </p>

        <p>
          <b>Madde 8. Yürürlülük</b>
          <br />
          <br />
          Müşteri nin, Programı kullanmaya başlamak için Site üzerinde sunulan
          formu doldurup işbu Sözleşme’yi onaylaması Müşteri’nin işbu
          Sözleşme’yi okuduğu ve kabul ettiği anlamına gelir. İşbu Sözleşme
          Müşteri’nin Program’da Sözleşme’yi onaylaması ile akdedilmiş ve
          karşılıklı olarak yürürlüğe girmiştir.
        </p>
      </div>
    </>
  );
}
