import "./uyeol.css";
import Buton from "../../components/Buton";
import menuLogo from "../../assets/img/Frame 5.png";
import resm1 from "../../assets/img/bgm3.jpeg";
import kr1 from "../../assets/img/kr1.jpeg";
import { useState, useRef } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function Uyeol() {
  const naviga = useNavigate();

  const showSwal = (titles, texts, icons) => {
    Swal.fire({
      title: titles,
      text: texts,
      icon: icons,
      confirmButtonText: "Tamam",
      timer: 5000,
      timerProgressBar: true,
    });
  };

  var styl3 = {
    marginTop: "20px",
    color: "#000",
    width: "99%",
    fontWeight: "400",
    textShadow: "1px 1px 1px #ccc",
    border: " 1px solid #fcc",
    boxShadow: " 0px 0px 15px #faa, 2px 2px 1px #333",
    textAlign: "center",
  };

  const [adsoyad, setAdsoyad] = useState("");
  const [unvan, setUnvan] = useState("");
  const [il, setIl] = useState("");
  const [ilce, setIlce] = useState("");
  const [ceptel, setCeptel] = useState("");
  const [eposta, setEposta] = useState("");
  const [gizlilik, setGizlilik] = useState(false);
  const [sozlesme, setSozlesme] = useState(false);
  const [acikrize, setAcikrize] = useState(false);

  const adsoyadRef = useRef();
  const unvanRef = useRef();
  const ilRef = useRef();
  const ilceRef = useRef();
  const ceptelRef = useRef();
  const epostaRef = useRef();
  const gizlilikRef = useRef();
  const sozlesmeRef = useRef();
  const acikrizeRef = useRef();

  const handelSubmid = async (e) => {
    e.preventDefault();
    if (adsoyad.trim().length < 3) {
      adsoyadRef.current.focus();
      showSwal("Ad Soyad boş olamaz !", "Lütfen boş bırakmayın.", "error");
    } else if (unvan.trim().length < 3) {
      unvanRef.current.focus();
      showSwal("Firma Ünvanı boş olamaz !", "Lütfen boş bırakmayın.", "error");
    } else if (il.trim().length === 0) {
      ilRef.current.focus();
      showSwal("İl bilgisi boş olamaz !", "Lütfen boş bırakmayın.", "error");
    } else if (ilce.trim().length === 0) {
      ilceRef.current.focus();
      showSwal("İlçe bilgisi boş olamaz !", "Lütfen boş bırakmayın.", "error");
    } else if (ceptel.trim().length !== 10 || ceptel[0] !== "5") {
      ceptelRef.current.focus();
      showSwal("Cep Telefonu Hatalı !", "Lütfen düzeltin.", "error");
    } else if (
      eposta.trim().length < 5 ||
      eposta.indexOf("@") === -1 ||
      eposta.indexOf(".") === -1
    ) {
      epostaRef.current.focus();
      showSwal("E-Posta Hatalı !", "Lütfen düzeltin.", "error");
    } else if (!(gizlilik && sozlesme && acikrize)) {
      gizlilikRef.current.focus();
      showSwal(
        "Eksik Onay Bulunuyor !",
        "Lütfen Onayları tamamlayın.",
        "error"
      );
    } else {
      //* KONTROL TAMAMLANDI ---
      let token = parseInt(999999999 * Math.random()).toString();
      // document.cookie = "token=" + token + "; max-age=3600; ";

      const formData = new FormData();
      formData.append("token", btoa(token));
      formData.append("company", unvan);
      formData.append("user", adsoyad);
      formData.append("il", il);
      formData.append("ilce", ilce);
      formData.append("email", eposta);
      formData.append("phone", ceptel);
      formData.append("istel", "");
      formData.append("promkod", "");
      formData.append("sozlesme", sozlesme);
      formData.append("gizlilikpolitikasi", gizlilik);
      formData.append("acikriza", acikrize);

      await fetch(
        process.env.REACT_APP_PHPBASEURL + "/08deneme/uye_kaydet.php",
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: btoa(token),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          if (json.hata) {
            showSwal("Bir hata oluştu !", json.hata, "error");
          } else if (json.ok) {
            showSwal("Üyelik Talebiniz Alındı", "Teşekkür ederiz.", "success");
            naviga("/uyualindi");
          }
        })
        .catch((err) => {
          showSwal("Bir hata oluştu !", err, "error");
        });
    }
  };

  return (
    <div className="icerik" style={{ backgroundColor: "#FFF" }}>
      <section
        style={{
          padding: "50px 0px",
          position: "relative",
          overflow: "hidden",
          borderRadius: "30px",
          filter: "drop-shadow(5px 5px 5px #999)",
          marginBottom: "30px",
        }}
      >
        <img
          className="kedis"
          style={{
            position: "absolute",
            width: "100%",
            opacity: "1",
            filter: "contrast(2)",
            top: "0px",
            zIndex: "-1",
          }}
          src={kr1}
          alt="PratikVet"
        ></img>
        <div className="row">
          <div style={{ margin: "10px 0", textAlign: "center" }}>
            <img
              src={menuLogo}
              alt="PratikPet"
              style={{ width: "50%", height: "50%" }}
            ></img>
            <div className="uyeyaz">ÜYELİK KAYDI</div>
          </div>
        </div>
      </section>
      <div className="row" style={{ justifyContent: "space-between" }}>
        <form className="uyeform" onSubmit={handelSubmid}>
          <div className="row rowkuc">
            <label>Ad Soyad :</label>
            <input
              value={adsoyad}
              ref={adsoyadRef}
              onChange={(e) => setAdsoyad(e.target.value)}
              type="text"
              autoFocus
            />
          </div>
          <div className="row rowkuc">
            <label>Pet Shop Ünvanı :</label>
            <input
              value={unvan}
              ref={unvanRef}
              onChange={(e) => setUnvan(e.target.value)}
              type="text"
            />
          </div>
          <div className="row rowkuc">
            <label>İL :</label>
            <input
              value={il}
              ref={ilRef}
              onChange={(e) => setIl(e.target.value)}
              type="text"
            />
          </div>
          <div className="row rowkuc">
            <label>İlçe :</label>
            <input
              value={ilce}
              ref={ilceRef}
              onChange={(e) => setIlce(e.target.value)}
              type="text"
            />
          </div>
          <div className="row rowkuc">
            <label>Cep Tel. :</label>
            <input
              value={ceptel}
              ref={ceptelRef}
              onChange={(e) => {
                if (e.target.value.length > 10) {
                  setCeptel(e.target.value.substring(0, 10));
                } else {
                  setCeptel(e.target.value);
                }
              }}
              type="number"
              placeholder="5XXXXXXXXX"
            />
          </div>
          <div className="row rowkuc">
            <label>E-Posta :</label>
            <input
              value={eposta}
              ref={epostaRef}
              onChange={(e) => setEposta(e.target.value)}
              type="email"
            />
          </div>
          <div className="row">
            <label style={{ width: "auto", cursor: "pointer" }}>
              <input
                style={{
                  width: "25px",
                  height: "25px",
                  marginRight: "10px",
                  accentColor: "blue",
                }}
                value={gizlilik}
                ref={gizlilikRef}
                onChange={(e) => setGizlilik(!gizlilik)}
                type="checkbox"
              />
              <a className="altCiz" href="/gizlilik">
                Gizlilik Politikası
              </a>{" "}
              metnini okudum ve onaylıyorum.
            </label>
          </div>
          <div className="row">
            <label style={{ width: "auto", cursor: "pointer" }}>
              <input
                style={{
                  width: "25px",
                  height: "25px",
                  marginRight: "10px",
                  accentColor: "blue",
                }}
                value={sozlesme}
                ref={sozlesmeRef}
                onChange={(e) => setSozlesme(!sozlesme)}
                type="checkbox"
              />
              <a className="altCiz" href="/sozlesme">
                Kullanıcı Sözleşmesi
              </a>{" "}
              metnini okudum ve onaylıyorum.
            </label>
          </div>
          <div className="row">
            <label style={{ width: "auto", cursor: "pointer" }}>
              <input
                style={{
                  width: "25px",
                  height: "25px",
                  marginRight: "10px",
                  accentColor: "blue",
                }}
                value={acikrize}
                ref={acikrizeRef}
                onChange={(e) => setAcikrize(!acikrize)}
                type="checkbox"
              />
              <a className="altCiz" href="/acikriza">
                KVKK Açık Rıza
              </a>{" "}
              metnini okudum ve onaylıyorum.
            </label>
          </div>
          <Buton
            icerik={"ÜYELİK TALEBİ GÖNDER"}
            sekil={styl3}
            oncl={handelSubmid}
          />
        </form>
        <div className="sagtrf">
          <section
            style={{
              padding: "300px 0px",
              position: "relative",
              overflow: "hidden",
              borderRadius: "0px",
              filter: "drop-shadow(5px 5px 15px #888)",
            }}
          >
            <img
              className="yanres"
              style={{
                position: "absolute",
                width: "100%",
                filter: "contrast(2)",
                top: "0px",
              }}
              src={resm1}
              alt="PratikVet"
            ></img>
          </section>
        </div>
      </div>
    </div>
  );
}
