import "../../assets/css/index.css";
import "./home.css";

import { useEffect } from "react";

import cat1 from "../../assets/img/ci1.jpeg";
import dog2 from "../../assets/img/ci2.jpeg";
import dog1 from "../../assets/img/ci4.jpeg";
import Buton from "../../components/Buton";
import menuLogo from "../../assets/img/Frame 5.png";
import fonbas from "../../assets/img/d9.jpeg";
import fonort from "../../assets/img/kediler.jpeg";
import fonalt from "../../assets/img/bg13.jpg";
import yurked from "../../assets/img/cat-walking.gif";

export default function Home() {
  var styl = {
    fontSize: "30px",
    color: "#000",
    textShadow: "1px 1px 1px #000",
    border: " 1px solid #fcc",
    boxShadow: " 0px 0px 15px #faa, 2px 2px 1px #333",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="icerik" style={{ backgroundColor: "#FFF" }}>
      <section
        style={{
          padding: "50px 0px",
          position: "relative",
          overflow: "hidden",
          borderRadius: "30px",
          filter: "drop-shadow(5px 5px 5px #999)",
        }}
      >
        <img
          className="kedis"
          style={{
            position: "absolute",
            width: "100%",
            opacity: "1",
            filter: "contrast(2)",
            top: "0px",
          }}
          src={fonbas}
          alt="PratikVet"
        ></img>
        <div
          className="row"
          style={{
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div className="hirobaslik">
            Pet Shop yönetmenin en Pratik ve Etkili yolu
          </div>

          <div style={{ margin: "20px 0" }}>
            <img src={menuLogo} alt="PratikPet" className="hiroimg"></img>
          </div>

          <div className="hirobaslik">Pratik ve Güçlü Pet Shop Yazılımı</div>
        </div>
      </section>

      <div className="row">
        <div style={{ width: "60%", paddingRight: "10px" }}>
          <p
            style={{
              textIndent: "30px",
              textAlign: "justify",
              textShadow: "none",
              background:
                "linear-gradient(45deg,#1500FF, #f32170, #ff6b08,#cf23cf)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Siz işinize odaklanın, gerisini PratikPet halletsin. Pet Shop için
            Güçlü, Pratik ve Kolay kullanımlı yardımcınız. İşletmenizin tüm
            hesaplarını, tek noktadan yönetin. PratikPet ile kontrol sizde
            olsun.
          </p>
        </div>
        <div
          className="fill"
          style={{ width: "40%", height: "300px", paddingLeft: "10px" }}
        >
          <img
            src={cat1}
            style={{ objectPosition: "0px" }}
            alt="PratikPet"
          ></img>
        </div>
      </div>

      <section
        style={{
          padding: "50px 0px",
          position: "relative",
          overflow: "hidden",
          borderRadius: "30px",
        }}
      >
        <img
          className="ortaln"
          style={{
            position: "absolute",
            width: "100%",
            opacity: "1",
            top: "0px",
          }}
          src={fonort}
          alt="PratikVet"
        ></img>
        <div
          className="row"
          style={{
            justifyContent: "center",
            color: "#ff2200",
            fontWeight: "300",
          }}
        >
          <div
            className="hirobaslik"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            Hemen
            <Buton icerik={"Ücretsiz Üye Ol"} sekil={styl} git={"/uyeol"} />
            14 gün ücretsiz dene.
          </div>
        </div>
        <img
          className="catwals2"
          style={{
            position: "absolute",
            bottom: "3px",
          }}
          src={yurked}
          alt="PratikVet"
        ></img>
      </section>

      <div className="row">
        <div
          className="fill"
          style={{ width: "40%", height: "300px", paddingRight: "10px" }}
        >
          <img
            src={dog1}
            style={{ objectPosition: "0px" }}
            alt="PratikPet"
          ></img>
        </div>

        <div style={{ width: "60%", paddingLeft: "10px" }}>
          <p
            style={{
              textIndent: "30px",
              textAlign: "justify",
              textShadow: "none",
              background:
                "linear-gradient(45deg, #f32170, #ff6b08,#cf23cf, #1500FF)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            PratikPet ile işinizi düzenli ve etkili bir şekilde yönetin. Kağıt
            karmaşasından, Excel çilesinden kurtulun! Hızlı, pratik ve kullanıcı
            dostu arayüzü sayesinde işinize odaklanın, verimliliği artırın.
            Bilgilerinizi tek bir merkezde toplayın, takibi kolaylaştırın ve
            kontrolü hemen elde edin.
          </p>
        </div>
      </div>

      <div style={{ marginBottom: "40px" }}>
        <img
          className="catwals"
          style={{
            position: "absolute",
            height: "38px",
          }}
          src={yurked}
          alt="PratikVet"
        ></img>
      </div>

      <section
        style={{
          padding: "50px 0px",
          position: "relative",
          overflow: "hidden",
          borderRadius: "10px",
        }}
      >
        <img
          className="altaln"
          style={{
            position: "absolute",
            width: "100%",
            opacity: "1",
            top: "0px",
          }}
          src={fonalt}
          alt="PratikVet"
        ></img>
        <div
          className="row"
          style={{
            justifyContent: "center",
            color: "#ff2200",
            fontWeight: "300",
          }}
        >
          <div className="hirobaslik">
            Siz İşinize odaklanın, gerisini PratikPet halletsin.
          </div>
        </div>
      </section>

      <div className="row" style={{ margin: "30px 0" }}>
        <div style={{ width: "80%", paddingRight: "10px" }}>
          <p
            style={{
              textAlign: "justify",
              textShadow: "none",
              background: "linear-gradient(45deg,#1500FF, #FC72FA,#000000)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            PratikPet.Com bir Pratik Yönet Ltd. Şti. Ürünüdür.
            <br />
            Pratik Yönet Limited Şirketi
            <br />
            Mersis No: 0137030914600015
          </p>
        </div>
        <div
          className="fill"
          style={{ width: "20%", height: "300px", paddingLeft: "10px" }}
        >
          <img
            src={dog2}
            style={{ objectPosition: "0px" }}
            alt="PratikPet"
          ></img>
        </div>
      </div>
    </div>
  );
}
