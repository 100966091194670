import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import menuLogo from "../assets/img/Frame 5.png";
import Buton from "../components/Buton";
import "./navbar.css";

var styl1 = {
  color: "#000",
  textShadow: "1px 1px 1px #000",
  border: "1px solid #cfc",
  boxShadow: "0px 0px 15px #afa, 2px 2px 1px #333",
};
var styl2 = {
  color: "#000",
  textShadow: "1px 1px 1px #000",
  border: " 1px solid #fcc",
  boxShadow: " 0px 0px 15px #faa, 2px 2px 1px #333",
};

export default function Navbar() {
  const [checked, setChecked] = useState(false);
  function clickHandel() {
    setChecked(!checked);
  }
  useEffect(() => {
    setChecked(false);
  }, []);

  return (
    <div className="navbar">
      <div
        className="logo"
        onClick={() => {
          setChecked(false);
        }}
      >
        <NavLink to="/">
          <img src={menuLogo} height={40} alt="PratikPet"></img>
        </NavLink>
      </div>

      <input
        type="checkbox"
        id="brgBtn"
        checked={checked}
        onChange={clickHandel}
      />

      <div className="menu">
        <div className="solMenu">
          <div className="menuItem" onClick={clickHandel}>
            <NavLink to="/ozellikler">Özellikler</NavLink>
          </div>
          <div className="menuItem" onClick={clickHandel}>
            <NavLink to="/fiyatlar">Fiyatlar</NavLink>
          </div>
          <div className="menuItem" onClick={clickHandel}>
            <NavLink to="/iletisim"> İletişim</NavLink>
          </div>
          <div className="dgr">
            Diğer ▾
            <div className="menuDiger">
              <div className="menuItem" onClick={clickHandel}>
                <NavLink to="/gizlilik"> Gizlilik Politikası</NavLink>
              </div>
              <div className="menuItem" onClick={clickHandel}>
                <NavLink to="/sozlesme"> Kullanıcı Sözleşmesi</NavLink>
              </div>
              <div className="menuItem" onClick={clickHandel}>
                <NavLink to="/acikriza"> Açık Rıza Metni</NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="sagMenu">
          <Buton
            oncl={() => {
              setChecked(false);
            }}
            icerik={"Giriş Yap"}
            sekil={styl1}
            git={"/giris"}
          />
          <Buton
            oncl={() => {
              setChecked(false);
            }}
            icerik={"Ücretsiz Üye Ol"}
            sekil={styl2}
            git={"/uyeol"}
          />
        </div>
      </div>
      <label className="burger" htmlFor="brgBtn">
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line2"></div>
      </label>
    </div>
  );
}
