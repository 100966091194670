import { NavLink } from "react-router-dom";
import "./buton.css";

export default function Buton({ icerik, sekil, git, oncl }) {
  return (
    <>
      <div className="buton" style={sekil} onClick={oncl}>
        <NavLink to={git} style={{ display: "block", padding: "5px 20px" }}>
          {icerik}
        </NavLink>
      </div>
    </>
  );
}
