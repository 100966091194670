import "./giris.css";
import Buton from "../../components/Buton";
import menuLogo from "../../assets/img/Frame 5.png";
import kr2 from "../../assets/img/kr2.jpeg";
import { useState, useRef } from "react";
import Swal from "sweetalert2";
const jose = require("jose");

var styl3 = {
  marginTop: "20px",
  color: "#000",
  width: "99%",
  fontWeight: "400",
  textShadow: "1px 1px 1px #ccc",
  border: " 1px solid #fcc",
  boxShadow: " 0px 0px 15px #faa, 2px 2px 1px #333",
  textAlign: "center",
};
const showSwal = (titles, texts, icons) => {
  Swal.fire({
    title: titles,
    text: texts,
    icon: icons,
    confirmButtonText: "Tamam",
    timer: 5000,
    timerProgressBar: true,
  });
};
const showLoad = () => {
  Swal.fire({
    title: "Lütfen bekleyiniz ...",
    showConfirmButton: false,
    allowOutsideClick: false,
    timer: 0,
    timerProgressBar: true,
    didOpen: () => {
      Swal.showLoading();
    },
  });
};

export default function Giris() {
  const secret = new TextEncoder().encode(process.env.REACT_APP_SCRET_KEY);

  const [ceptel, setCeptel] = useState("");
  const [sifre, setSifre] = useState("");
  const [hatirla, setHatirla] = useState(false);

  const ceptelRef = useRef();
  const sifreRef = useRef();
  const hatirlaRef = useRef();

  const crtToken = async (param) => {
    param.bh = hatirla ? "1" : "0";
    const alg = { alg: "HS256", typ: "JWT" };
    const data = param;
    const ddd = await new jose.SignJWT({ data })
      .setProtectedHeader(alg)
      .setIssuedAt()
      .setAudience(process.env.REACT_APP_RCTBASEURL)
      .setIssuer(process.env.REACT_APP_RCTBASEURL)
      .setExpirationTime(hatirla ? "90d" : "1d")
      .sign(secret);
    return ddd;
  };

  const giHandelSubmid = async (e) => {
    e.preventDefault();

    if (ceptel.trim().length !== 10) {
      ceptelRef.current.focus();
      showSwal("Cep Telefonu Hatalı !", "Lütfen düzeltin.", "error");
    } else if (sifre.trim().length === 0) {
      sifreRef.current.focus();
      showSwal("Şifre Hatalı !", "Lütfen düzeltin.", "error");
    } else {
      showLoad();

      //* KONTROL TAMAMLANDI ---
      const token = parseInt(999999999 * Math.random()).toString();
      // document.cookie = "token=" + token + "; max-age=3600; secure;";

      const formData = new FormData();
      formData.append("token", btoa(token));
      formData.append("kcep", ceptel);
      formData.append("ksif", btoa(sifre));
      formData.append("bh", hatirla ? 1 : 0);

      await fetch(
        process.env.REACT_APP_PHPBASEURL + "/corporate/kullanicikontrol.php",
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: btoa(token),
          },
        }
      )
        .then((response) => response.json())
        .then(async (json) => {
          console.log(json.data);
          Swal.close();
          if (json.hata) {
            showSwal("Bir hata oluştu !", json.hata, "error");
          } else if (json.ok) {
            //! --- LOGİN OLDU
            let jwt = await crtToken(json.data);
            console.log("aaaaaa");
            document.cookie =
              "azm=" +
              jwt +
              ";phat=/; secure;" +
              (hatirla ? "max-age=7776000" : "");
            document.cookie =
              "G45er81dK9d=" +
              json.data.mst_tbladi +
              ";phat=/; secure;" +
              (hatirla ? "max-age=7776000" : "");
            document.cookie =
              "k74F51q9we2=" +
              json.data.kuki +
              ";phat=/; secure;" +
              (hatirla ? "max-age=7776000" : "");

            if (json.data.kln_ytgid === "1") {
              window.location.replace(
                process.env.REACT_APP_PHPBASEURL + "/07asadmin/genel.php"
              );
            } else if (json.data.kln_ytgid === "2") {
              window.location.replace(
                process.env.REACT_APP_PHPBASEURL + "/00genel/genel.php"
              );
            }

            showSwal(`Merhaba, ${json.data.kln_kadi}`, "", "success");
          }
        })
        .catch((err) => {
          Swal.close();
          showSwal("Bir hata oluştu !", err, "error");
        });
    }
  };

  return (
    <div className="icerik" style={{ backgroundColor: "#FFF" }}>
      <section
        style={{
          padding: "50px 0px",
          position: "relative",
          overflow: "hidden",
          borderRadius: "30px",
          filter: "drop-shadow(5px 5px 5px #999)",
          marginBottom: "30px",
        }}
      >
        <img
          className="kedis"
          style={{
            position: "absolute",
            width: "100%",
            opacity: "1",
            filter: "contrast(2)",
            top: "0px",
            zIndex: "-1",
          }}
          src={kr2}
          alt="PratikVet"
        ></img>
        <div className="row">
          <div style={{ margin: "10px 0", textAlign: "center" }}>
            <img
              src={menuLogo}
              alt="PratikPet"
              style={{ width: "50%", height: "50%" }}
            ></img>
            <div className="uyeyazgi">ÜYELİK GİRİŞİ</div>
          </div>
        </div>
      </section>
      <div className="row">
        <form className="uyeformgi" onSubmit={giHandelSubmid}>
          <div className="row rowkuc" style={{ flexWrap: "nowrap" }}>
            <label style={{ textWrap: "nowrap" }}>Cep Tel. :</label>
            <input
              value={ceptel}
              ref={ceptelRef}
              onChange={(e) => {
                if (e.target.value.length > 10) {
                  setCeptel(e.target.value.substring(0, 10));
                } else {
                  setCeptel(e.target.value);
                }
              }}
              type="number"
              placeholder="5XXXXXXXXX"
              autoFocus
            />
          </div>
          <div className="row rowkuc" style={{ flexWrap: "nowrap" }}>
            <label style={{ textWrap: "nowrap" }}>Şifre :</label>
            <input
              value={sifre}
              ref={sifreRef}
              onChange={(e) => setSifre(e.target.value)}
              type="password"
            />
          </div>
          <div className="row rowkuc">
            <label style={{ cursor: "pointer" }}>
              <input
                style={{
                  width: "25px",
                  height: "25px",
                  marginRight: "10px",
                  accentColor: "blue",
                }}
                value={hatirla}
                ref={hatirlaRef}
                onChange={(e) => setHatirla(!hatirla)}
                type="checkbox"
              />
              Beni Hatırla
            </label>
            <a
              className="altCiz"
              style={{ marginLeft: "30px" }}
              href="/sifreunuttum"
            >
              Şifremi Unuttum
            </a>
          </div>

          <Buton icerik={"GİRİŞ"} sekil={styl3} oncl={giHandelSubmid} />
        </form>
      </div>
    </div>
  );
}
