import "./gizlilik.css";

export default function Gizlilik() {
  return (
    <>
      <div className="icerik" style={{ backgroundColor: "#FFF" }}>
        <h1 style={{ color: "red" }}>PratikPet.Com</h1>
        <h3>
          <b>Gizlilik Politikamız</b>
        </h3>

        <h4>
          6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU KAPSAMINDA AYDINLATMA
          METNİ
        </h4>

        <p>
          Pratik Yönet Bilişim Sanayi ve Ticaret Limited Şirketi. Paşa Alanı
          Mah. 113. Sk. No:1/A Karesi / Balıkesir.(“Pratik Yönet”)(Bundan sonra
          "Şirket" olarak anılacaktır.) olarak özel hayatın gizliliğine saygı
          duyuyor ve önem veriyoruz. Bu nedenle, sizleri 6698 sayılı Kişisel
          Verilerin Korunması Kanunu(Bundan sonra “KVKK” olarak anılacaktır.)
          kapsamında kişisel verilerinizin kullanımı ve korunması ile ilgili
          haklarınız konusunda bilgilendirmek istiyoruz.
        </p>
        <ol type="1" className="ulOl">
          <li className="gizLim">
            <b>Veri Sorumlusunun Kimliği</b>
            <br />
            Pratik Yönet (Bundan sonra “Şirket” olarak anılacaktır.) kişisel
            verilerinizi,6698 sayılı Kişisel Verilerin Korunması Kanunu(Bundan
            sonra “KVKK” olarak anılacaktır.) ve ilgili sair mevzuat kapsamında,
            veri sorumlusu sıfatı ile işlediğimizi tarafınıza bildiriyoruz.
          </li>
          <li className="gizLim">
            <b>İşlenen Kişisel Veri Kategorileri</b>
            <br />
            KVKK ve ilgili sair mevzuata uygun olarak işbu 6698 sayılı Kişisel
            Verilerin Korunması Kanunu kapsamında işbu Aydınlatma Metin'inde
            belirtilen amaçlar ve hukuki sebeplerle aşağıda belirtilen
            kategorilerinde bulunan kişisel verileriniz işlenecektir.
            <ul>
              <li className="gizLim">Kimlik : Ad-Soyadı, TCKN, Müşteri ID.</li>
              <li className="gizLim">
                İletişim : E-Posta Adresi, Telefon Numarası, İş Yeri Adresi,
                Sosyal Medya Hesapları,
              </li>
              <li className="gizLim">
                Müşteri İşlem : Çağrı Merkezi Kayıtları, Sipariş Bilgileri,
                Mesaj Kayıtları.
              </li>
              <li className="gizLim">
                Pazarlama: Site Kullanımı Bilgileri (Çerezler), Anket, Form,
                Ticari İletiler.
              </li>
              <li className="gizLim">
                İşlem Güvenliği : Cihaz İşletim Sistemi ve Sürümü, Cihaz Türü
                Cihaz ID, Donanım Modeli, IP Adresi, Kullanıcı İşlem Kayıtları,
                Parola Bilgileri.
              </li>
              <li className="gizLim">
                Finans : Ödeme Yöntemlerine Bağlı Olarak Ödeme Kayıtları , IBAN
                Bilgisi, Vergi Levhası, Vergi Numarası, İmza Sirküleri
              </li>
              <li className="gizLim">
                Hukuki İşlem : Uyuşmazlık Olması Halinde Dava Dosyasındaki
                Bilgiler, İhtarnameler, Adli ve İdari Makamlarla Yazışmalardaki
                Bilgiler, Sözleşmeler.
              </li>
            </ul>
          </li>
          <li className="gizLim">
            <b>
              Kişisel Verilerinizin Hangi Hukuki Sebeplere İstinaden ve Hangi
              Amaçlarla İşleneceği
            </b>
            <br />
            Kişisel verileriniz a), b), c) maddeleri uyarınca açık rızanız
            olmaksızın tarafımızca işlenecektir. Ancak d) maddesinde sayılan
            kişisel verileriniz ancak özgür iradenizle açık rıza vermeniz
            şartıyla tarafımızca işlenebilmektedir.
            <ol type="a">
              <li className="gizLim">
                Kimlik, İletişim, İşlem Güvenliği, Finans, Hukuki İşlem ve
                Mesleki Deneyim kategorilerindeki verileriniz KVKK m.5/2(a) ve
                5/2(ç) uyarınca “Kanunlarda Öngörülmesi” ve “Veri Sorumlusunun
                Hukuki Yükümlülüklerini Yerine Getirebilmesi İçin Zorunlu
                Olması” hukuki sebeplerine istinaden:
                <ul>
                  <li className="gizLim">
                    İlgili mevzuatlar gereği saklama ve arşivleme
                    faaliyetlerinin yürütülmesi
                  </li>
                  <li className="gizLim">
                    Finans ve muhasebe işlemlerinin yürütülmesi
                  </li>
                  <li className="gizLim">
                    İlgili mevzuatlar gereği faaliyetlerin hukuka uygun
                    yürütülmesi
                  </li>
                  <li className="gizLim">
                    Sağlık Bakanlığı başta olmak üzere ilgili yetkili kişi,
                    kurum ve kuruluşlara bilgi verilmesi
                  </li>
                  <li className="gizLim">
                    Bilgi güvenliği süreçlerinin yürütülmesi
                  </li>
                  <li className="gizLim">
                    Hukuk işlerinin takibi ve yürütülmesi amaçlarıyla
                    işlenecektir
                  </li>
                </ul>
              </li>
              <li className="gizLim">
                Kimlik, İletişim, Finans, Mesleki Deneyim ve Hukuki İşlem
                kategorilerindeki verileriniz KVKK m.5/2(c) uyarınca
                “Tarafınızla aramızda sözleşmenin kurulması ve ifasıyla doğrudan
                doğruya ilgili olması sebebiyle gerekli olması” hukuki sebebine
                istinaden:
                <ul>
                  <li className="gizLim">
                    PratikPet olarak hizmet satış süreçlerinin yürütülmesi
                  </li>
                  <li className="gizLim">
                    Hizmet satışı sonrası destek hizmetlerinin yürütülmesi
                  </li>
                  <li className="gizLim">
                    Hizmet operasyon süreçlerinin yürütülmesi
                  </li>
                  <li className="gizLim">
                    İletişim faaliyetlerinin yürütülmesi
                  </li>
                  <li className="gizLim">
                    Saklama ve arşivleme faaliyetlerinin yürütülmesi amaçlarıyla
                    işlenecektir.
                  </li>
                </ul>
              </li>
              <li className="gizLim">
                Kimlik, İletişim, İşlem Güvenliği, Müşteri İşlem, Görsel
                Kayıtlar kategorilerindeki verileriniz KVKK m.5/2(c) uyarınca
                “İlgili kişinin temel hak ve özgürlüklerine zarar vermemek
                kaydıyla, veri sorumlusunun meşru menfaatleri için veri
                işlemenin zorunlu olması” hukuki sebebine istinaden:
                <ul>
                  <li className="gizLim">
                    Veri sorumlusu operasyonlarının güvenliğinin sağlanması
                  </li>
                  <li className="gizLim">
                    Kimlik doğrulama işlemlerinin yapılması
                  </li>
                  <li className="gizLim">
                    Denetim faaliyetlerinin yürütülmesi
                  </li>
                  <li className="gizLim">
                    İnternet sitesinin işlevsel hale getirilmesi
                  </li>
                  <li className="gizLim">
                    İş ilişkisi içerisinde olan ilgili kişilerin hukuki, teknik
                    ve ticari iş güvenliğinin temin edilmesi amaçlarıyla
                    işlenecektir.
                  </li>
                </ul>
              </li>
              <li className="gizLim">
                KVKK m.5/2(a) uyarınca “açık rızanızın olması” hukuki sebebine
                istinaden pazarlama verileriniz bu aydınlatma metnine ek olarak
                tarafınıza sunulan “Açık Rıza Metni”nde açık rıza vermeniz
                kaydıyla aşağıdaki amaçlarla işlenebilecektir.
                <br />
                Pazarlama : Özgür iradeniz rıza vermeniz kaydıyla ürün ve
                hizmetlerimiz ile ilgili olarak pazarlama faaliyetleri
                kapsamında tarafınızla iletişime geçilmesi, ticari elektronik
                ileti gönderilmesi, anket ve memnuniyet çalışmalarının
                yapılması, internet sitesi üzerinden reklam amaçlı çerezlerin
                toplanması amaçlarıyla kişisel verileriniz işlenebilecektir.
              </li>
            </ol>
          </li>
          <li className="gizLim">
            <b>
              İşlenen Kişisel Verilerinizin Kimlere ve Hangi Amaçla
              Aktarılabileceği{" "}
            </b>
            <br />
            Veri sorumlusu olarak işlediğimiz verileri aşağıda belirtilen
            amaçlarla ve belirtilen kişi gruplarıyla yalnızca yurt içinde
            aktarmaktayız.
            <br />
            Muhasebecilerimiz : Finans ve muhasebe işlemlerinin yürütülmesi
            için; Kimlik Verileriniz, Finans Verileriniz, Müşteri İşlem,
            İletişim Verileriniz aktarılabilir
            <br />
            Avukatlarımız : Hukuki iş ve işlemlerin yürütülmesi ve takibi için;
            Kimlik Verileriniz, Hukuki İşlem Verileriniz, Müşteri İşlem
            Verileriniz, İletişim Verileriniz aktarılabilir.
            <br />
            Yetkili Kurum ve Kuruluşlar : Faaliyetlerin mevzuata uygun şekilde
            yürütülmesi için, resmi talep halinde Yetkili kişi, kurum ve
            kuruluşlara bilgi verilmesi için Kimlik Verileriniz, Finans
            Verileriniz, İletişim Verileriniz, Mesleki Deneyim Verileriniz,
            İşlem Güvenliği Verileriniz aktarılabilir.
          </li>
          <li className="gizLim">
            <b>Kişisel Verilerinizin Hangi Yöntemlerle Toplandığı</b>
            <br />
            Kişisel verileriniz, bu metnin (3) fıkrasında belirtilen hukuki
            sebeplere istinaden ve belirtilen amaçların yerine getirilebilmesi
            için otomatik yöntemlerle ve bazı durumlarda otomatik olmayan
            yöntemlerle sözlü, yazılı veya elektronik ortamda elde edilmektedir.
            Bu yöntemler şunlardır: İlgili kişi tarafından form doldurulması,
            e-posta iletisi gönderilmesi, telefon araması ile iletişime
            geçilmesi, internet sitesinin ziyaret edilmesi (çerez toplanması),
            internet sitesi üzerinden kayıt oluşturulması, mesaj yoluyla
            iletişime geçilmesi, sosyal medya platformları üzerinden irtibata
            geçilmesi, başka platformlarda verilerinizi alenileştirilmiş
            olmanız, yapılan görüşmelerde manuel olarak alınan notlar.
          </li>
          <li className="gizLim">
            <b>KVKK’nın 11. Maddesi Kapsamındaki Haklarınız </b>
            <br />
            KVKK m.11 ve ilgili mevzuat kapsamında, kişisel verileriniz ile
            ilgili olarak;
            <ul>
              <li className="gizLim">
                (a) Kişisel veri işlenip işlenmediğini öğrenme,
              </li>
              <li className="gizLim">
                (b) Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
              </li>
              <li className="gizLim">
                (c) Kişisel verilerin işlenme amacını ve bunların amacına uygun
                kullanılıp kullanılmadığını öğrenme,
              </li>
              <li className="gizLim">
                (d) Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı
                üçüncü kişileri bilme,
              </li>
              <li className="gizLim">
                (e) Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
                bunların düzeltilmesini isteme,
              </li>
              <li className="gizLim">
                (f) KVKK 7. maddede öngörülen şartlar çerçevesinde kişisel
                verilerin silinmesini veya yok edilmesini isteme,
              </li>
              <li className="gizLim">
                (g) (e) ve (f) bentleri uyarınca yapılan işlemlerin, kişisel
                verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
              </li>
              <li className="gizLim">
                (h) İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla
                analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun
                ortaya çıkmasına itiraz etme,
              </li>
              <li className="gizLim">
                (ı) Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
                zarara uğraması hâlinde zararın giderilmesini talep etme
                haklarına sahipsiniz.
              </li>
            </ul>
          </li>
          <li className="gizLim">
            <b>Kişisel Verilere İlişkin Kanuni Hakların Kullanılması </b>
            <br />
            Kişisel verileriniz ile ilgili haklarınızı kullanmak için,
            taleplerinizi, “Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında
            Tebliğ” uyarınca sistemimizde kayıtlı bulunan elektronik posta ile
            iletebilirsiniz.
          </li>
          <li className="gizLim">
            <b>Değişiklik ve Güncellemeler </b>
            <br />
            İş bu aydınlatma metni, 6698 sayılı Kişisel Verilerin Korunması
            Kanunu ve ilgili sair mevzuat kapsamında hazırlanmıştır. İlgili
            yasal mevzuat ve/veya Şirket’in kişisel veri işleme amaç ve
            politikalarında meydana gelecek değişiklikler doğrultusunda bahse
            konu aydınlatma metninde gerekli değişiklikler yapılabilir.
            Değişiklik yapılması durumunda uygun ortamlar aracılığıyla yeni
            metinlerimiz tarafınıza sunulacaktır. Aydınlatma Metninin en güncel
            haline https://PratikPet.com adresinden ulaşabilirsiniz
          </li>
        </ol>
      </div>
    </>
  );
}
