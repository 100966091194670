import "./prices.css";
import Buton from "../../components/Buton";
import menuLogo from "../../assets/img/Frame 5.png";
import res2 from "../../assets/img/kk2.jpeg";

export default function Prices() {
  var styl = {
    color: "#000",
    textShadow: "1px 1px 1px #000",
    border: " 1px solid #fcc",
    boxShadow: " 0px 0px 15px #faa, 2px 2px 1px #333",
  };

  return (
    <>
      <div className="icerik" style={{ backgroundColor: "#FFF" }}>
        <section
          style={{
            padding: "50px 0px",
            position: "relative",
            overflow: "hidden",
            borderRadius: "30px",
            filter: "drop-shadow(5px 5px 5px #999)",
            marginBottom: "30px",
          }}
        >
          <img
            className="kedis"
            style={{
              position: "absolute",
              width: "100%",
              opacity: "1",
              filter: "contrast(2)",
              top: "0px",
              zIndex: "-1",
            }}
            src={res2}
            alt="PratikVet"
          ></img>
          <div className="row">
            <div style={{ margin: "20px 0" }} className="hirologo">
              <img
                style={{ width: "100%" }}
                src={menuLogo}
                alt="PratikPet"
              ></img>
              <div className="hiroyazi">Pet Shop Yazılım Fiyatlarımız</div>
            </div>
          </div>
        </section>

        <section className="kosullar">
          <div className="row">
            <div className="pl">
              Üyelik satın almadan önce
              <Buton icerik={"Ücretsiz Üye Ol"} sekil={styl} git={"/uyeol"} />
              ile 14 gün ücretsiz deneyebilirsiniz.
            </div>
          </div>
        </section>

        <section className="kosullar">
          <div className="row" style={{ justifyContent: "space-around" }}>
            <div className="card1">
              <div className="card-head" style={{ color: "red" }}>
                1 AYLIK ÜYELİK
              </div>
              <div className="card-body">500 TL</div>
            </div>

            <div className="card1">
              <div className="card-head" style={{ color: "darkviolet" }}>
                1 YILLIK ÜYELİK
              </div>
              <div className="card-body">5.000 TL</div>
            </div>
          </div>
        </section>

        <div
          className="row"
          style={{
            justifyContent: "left",
            fontSize: "22px",
            marginTop: "10px",
            textShadow:
              "0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1)",
          }}
        >
          * Fiyatlara KDV dahildir.
          <br />
          * Bir Yıllık Üyelik alımı, 30 gün içinde iptal edilmek istenirse,
          paranın tamamı iade edilir.
          <br />* Bir Yıllık üyelik alımlarında, 30 günden sonra üyelik iptal
          edilmek istenirse, bir aylık üyelik ücreti üzerinden ve kullanılan gün
          sayısına göre hesaplama yapılarak ( alacak kalır ise ) geri ödeme
          yapılır.
        </div>
      </div>
    </>
  );
}
