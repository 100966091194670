import "./features.css";
import menuLogo from "../../assets/img/Frame 5.png";
import fonbas from "../../assets/img/c2.jpg";
import fonort from "../../assets/img/d5.jpeg";
import yurked from "../../assets/img/cat-walking.gif";
import { useEffect, useState } from "react";

export default function Features() {
  const [winTop, setWinTop] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (winTop > 100) {
      let bsrsm = document.querySelector(".basresm");
      bsrsm.classList.add("basresm2");
    } else if (winTop <= 5) {
      let bsrsm = document.querySelector(".basresm");
      bsrsm.classList.remove("basresm2");
    }
  }, [winTop]);

  let liElmnts = document.querySelectorAll("#myUl li");
  document.addEventListener("scroll", (event) => {
    setWinTop(window.scrollY);

    if (winTop > 19) {
      liElmnts.forEach((element, inx) => {
        liElmnts[inx].classList.add("my-li");
      });
    }
  });

  return (
    <>
      <div className="icerik" style={{ backgroundColor: "#FFF" }}>
        <section
          className="basresm"
          style={{
            position: "relative",
            overflow: "hidden",
            height: "82vh",
            transition: "height 1s",
          }}
        >
          <img
            className="ozelse"
            style={{
              position: "absolute",
              width: "100%",
              top: "0px",
            }}
            src={fonbas}
            alt="PratikVet"
          ></img>
          <div className="logohrkt" style={{ margin: "20px" }}>
            <img src={menuLogo} alt="PratikPet" className="hiroimg"></img>
            <h1
              className="renkYazi"
              style={{
                fontFamily: "cursive, fantasy",
                fontWeight: "bold",
                letterSpacing: "10px",
                background:
                  "linear-gradient(90deg,#FFFFFF, #F6FF00,#FF00FF, #00FFF2)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                textShadow: "none",
                textAlign: "center",
              }}
            >
              Özelliklerimiz
            </h1>
          </div>
        </section>
        <div style={{ marginBottom: "40px" }}>
          <img
            className="catwals"
            style={{
              position: "absolute",
              height: "38px",
            }}
            src={yurked}
            alt="PratikVet"
          ></img>
          <img
            className="catwals2"
            style={{
              position: "absolute",
              height: "38px",
            }}
            src={yurked}
            alt="PratikVet"
          ></img>
        </div>
        <section
          style={{
            position: "relative",
            overflow: "hidden",
          }}
        >
          <img
            className="lister"
            style={{
              borderTop: "1px solid black",
              position: "absolute",
              width: "100%",
              top: "0px",
              filter: "grayscale(1)",
            }}
            src={fonort}
            alt="PratikVet"
          ></img>
          <div className="row" style={{ justifyContent: "start" }}>
            <ul id="myUl" style={{ zIndex: "1" }}>
              <li className="myLi">
                <h1 style={{ color: "blue" }}>
                  En Güçlü ve Pratik Pet Shop Yazılımı
                </h1>
              </li>
              <li className="myLi">
                <h4 style={{ color: "purple" }}>Bazı Özelliklerimiz ;</h4>
              </li>
              <li className="myLi">İnternet Olan Her Yerden Erişim</li>
              <li className="myLi">Sınırsız Müşteri ve Ürün Kaydı</li>
              <li className="myLi">Ücretsiz Güncellemeler</li>
              <li className="myLi">
                Üyelik Bedeli Haricinde Hiçbir Ek Ücret Alınmaz
              </li>
              <li className="myLi">Sınırsız Kullanıcı Tanımlama</li>
              <li className="myLi">Kullanıcı Bazlı Yetkilendirme</li>
              <li className="myLi">Anlaşılır ve Güçlü Raporlama</li>
              <li className="myLi">
                Bilgisayar, Tablet yada Cep'ten Kolay Kullanım
              </li>
              <li className="myLi">
                Müşteri ve Tedarikçi Kayıtları'nın Tutulması
              </li>
              <li className="myLi">
                Müşteri ve Tedarikçi Borç ve Alacak Takibi
              </li>
              <li className="myLi">Müşteri ve Tedarikçi Vade Takibi</li>
              <li className="myLi">Müşteri Satış ve Tahsilat İşlemleri</li>
              <li className="myLi">Tedarikçi Alış ve Ödeme İşlemleri</li>
              <li className="myLi">Müşteri Karlılık Takibi</li>
              <li className="myLi">Ürün ve Hizmet Kayıtları'nın Tutulması</li>
              <li className="myLi">Ürün Stoklarının Takibi</li>
              <li className="myLi">Minimum Stok Miktarı Kontrolü</li>
              <li className="myLi">Ürün ve Hizmet Maliyet Kontrolü</li>
              <li className="myLi">Ürün SKT, SeriNo ve Etiket Takibi</li>
              <li className="myLi">Barkod ile Satış ve Alış İşlemleri</li>
              <li className="myLi">Randevu Takibi</li>
              <li className="myLi">Gelişmiş SMS Modülü</li>
              <li className="myLi">Toplu SMS Modülü</li>
              <li className="myLi">Konaklama & Pansiyon Modülü</li>
              <li className="myLi">Kategori ve Marka Bazlı Raporlamalar</li>
            </ul>
          </div>
        </section>
      </div>
      <div style={{ marginBottom: "40px" }}>
        <img
          className="catwals"
          style={{
            position: "absolute",
            height: "38px",
          }}
          src={yurked}
          alt="PratikVet"
        ></img>
      </div>
    </>
  );
}
