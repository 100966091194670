import "./iletisim.css";
import menuLogo from "../../assets/img/Frame 5.png";
import res1 from "../../assets/img/kk1.jpeg";

export default function Iletisim() {
  return (
    <>
      <div className="icerik" style={{ backgroundColor: "#FFF" }}>
        <section
          style={{
            padding: "50px 0px",
            position: "relative",
            overflow: "hidden",
            borderRadius: "30px",
            filter: "drop-shadow(5px 5px 5px #999)",
            marginBottom: "30px",
          }}
        >
          <img
            className="kedis"
            style={{
              position: "absolute",
              width: "100%",
              opacity: "1",
              filter: "contrast(2)",
              top: "0px",
              zIndex: "-1",
            }}
            src={res1}
            alt="PratikVet"
          ></img>
          <div className="row">
            <div style={{ margin: "20px 0" }} className="hirologoilt">
              <img
                src={menuLogo}
                alt="PratikPet"
                style={{ width: "70%" }}
              ></img>
              <div className="hiroyaziilt">İletişim</div>
            </div>
          </div>
        </section>

        <section style={{ marginBottom: "30px" }}>
          <div className="row">
            <div className="pl" style={{ textIndent: "30px" }}>
              Pet Shop'lar için hazırlanmış PratikPet.Com programı hakkında daha
              detaylı bilgi almak isterseniz aşağıdaki E-Posta, WhatsApp veya
              Cep Telefonumdan iletişime geçebilirsiniz,
            </div>
          </div>
        </section>

        <section>
          <div className="row" style={{ justifyContent: "space-around" }}>
            <div
              className="card2"
              style={{
                borderRadius: "50px",
                border: "1px solid #999",
                padding: "5px 0px",
              }}
            >
              <div
                className="card-head"
                style={{
                  color: "red",
                  fontWeight: "700",
                  letterSpacing: "5px",
                }}
              >
                Cep Tel. Ara
              </div>
              <div className="card-body">
                <a href="tel:+905055830386" target="_blank" rel="noreferrer">
                  0 (505) 583 03 86
                </a>
              </div>
            </div>

            <div
              className="card2"
              style={{
                borderRadius: "50px",
                border: "1px solid #999",
                padding: "5px 0px",
              }}
            >
              <div
                className="card-head"
                style={{
                  color: "green",
                  fontWeight: "700",
                  letterSpacing: "5px",
                }}
              >
                WhatsApp Mesajı Gönder
              </div>
              <div className="card-body">
                <a
                  href="https://api.whatsapp.com/send?phone=+905324465699"
                  target="_blank"
                  rel="noreferrer"
                >
                  0 (532) 446 56 99
                </a>
              </div>
            </div>

            <div
              className="card2"
              style={{
                borderRadius: "50px",
                border: "1px solid #999",
                padding: "5px 0px",
              }}
            >
              <div
                className="card-head"
                style={{
                  color: "purple",
                  fontWeight: "700",
                  letterSpacing: "5px",
                }}
              >
                E-Posta Gönder
              </div>
              <div className="card-body">
                <a
                  href="mailto:bilgi@pratikpet.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  bilgi@pratikpet.com
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
