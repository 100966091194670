import "./uyeol.css";
import menuLogo from "../../assets/img/Frame 5.png";

export default function Uyealindi() {
  return (
    <div className="icerik" style={{ backgroundColor: "#FFF" }}>
      <div className="row">
        <div style={{ margin: "10px 0", textAlign: "center" }}>
          <img
            src={menuLogo}
            alt="PratikPet"
            style={{ width: "70%", height: "50%" }}
          ></img>
          <div className="uyeyaz">ÜYELİK TALEBİ ALINDI</div>
        </div>
      </div>
      <div>PratikPet Ailesine Hoşgeldiniz,</div>
      <div>Sizi aramızda görmekten çok memnunuz.</div>
      <br />
      <div>14 Gün ücretsiz deneme talebiniz başarı ile alınmıştır.</div>
      <div>
        En kısa sürede sizinle iletişime geçerek, ücretsiz deneme sürecinizi
        başlatacağız.
      </div>
      <div>
        Her türlü sorularınız için iletişim bilgilerimiz üzerinden bize
        ulaşabilirsiniz.
      </div>
      <br />
      <div>
        Deneme sürecinizi kalıcı bir beraberliğe dönüştürmek için var gücümüzle
        çalışıyoruz.
      </div>
      <div>Saygılarımızla,</div>
      <div>
        <b>
          <i>pratikpet.com</i>
        </b>
      </div>
    </div>
  );
}
