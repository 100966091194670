import "./acikriza.css";

export default function Acikriza() {
  return (
    <>
      <div className="icerik" style={{ backgroundColor: "#FFF" }}>
        <h1 style={{ color: "red" }}>PratikPet.Com</h1>

        <h2>
          <b>Kişisel Verilerin Korunmasına İlişkin Açık Rıza Metni</b>
        </h2>
        <h4>
          <b>
            6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU (“KVKK”) UYARINCA
            KİŞİSEL VERİLERİN KORUNMASI HAKKINDA AÇIK RIZA METNİ
          </b>
        </h4>
        <p>
          Pratik Yönet Bilişim San.ve Tic. Ltd. Şti (PratikPet.com ve
          pratikpet.com marka sahibi) (Bundan sonra kısaca “PRATİK YÖNET” ya da
          “Şirket” olarak anılacaktır) olarak; veri sorumlusu sıfatıyla, 7 Nisan
          2016 tarihli ve 29677 Sayılı Resmî Gazete’de yayımlanan 6698 sayılı
          Kişisel Verilerin Korunması Kanunu (“Kanun”) ve ilgili mevzuat
          kapsamında istisnaya tabi olan hallerin haricinde, kişisel
          verilerinizin işlenmesi kapsamında aşağıdaki hususlara ilişkin açık
          rızanızı talep ediyoruz;
        </p>
        <p>
          Kanun ile PRATİK YÖNET Politikaları kapsamında düzenlenmiş olan ilgili
          hükümlerde belirtildiği üzere işlenmelerini gerektiren amaç
          çerçevesinde ve bu amaçla bağlantılı olarak sınırlı ve ölçülü bir
          şekilde,
        </p>
        <p>
          Tarafımıza bildirdiğiniz veya bildirildiği şekliyle olmak üzere
          doğruluğunu ve en güncel halini koruyarak, kaydedileceğini,
          depolanacağını, muhafaza edileceğini, yeniden düzenleneceğini,
          Kanun’da düzenlendiği üzere bu kişisel verileri talep etmeye yetkili
          olan kamu kurumları ve kuruluşları ile paylaşılacağını ve Kanun’un
          öngördüğü şartlarda, iş bu metinde aşağıda bahsedilen amaçlar
          dahilinde yurt içinde veya yurt dışında bulunan PRATİK YÖNET in
          hissedarlarına, iş ortaklarına, tedarikçilerine, ödeme ve/veya
          tahsilat aracılarına ve/veya üçüncü kişilere aşağıda belirtilen
          amaçlara ek olarak ;
        </p>

        <ul className="rizulOl">
          <li className="rizgizLim">
            Bilişim ve/veya yazılım ürünleri ve/veya hizmetleri, e-ticaret
            hizmetleri (ticaret platformları ve çevrimiçi ödeme/tahsilat aracı
            kurumları dahil) kapsamında ödeme ve/veya tahsilat hizmetlerinin
            gerçekleştirilmesi,
          </li>
          <li className="rizgizLim">
            Perakende satış ve hizmetlerinin sunulması,
          </li>
          <li className="rizgizLim">
            SMS (kısa mesaj) ve elektronik posta iletileri vasıtası ile ürün
            bilgilendirme ve pazarlama faaliyetlerinin gerçekleştirilmesi,
          </li>
          <li className="rizgizLim">
            PRATİK YÖNET ’e ait internet sitesi üzerinden toplanan kullanıcı
            çerezleri ve üye ve/veya müşterilerin e-posta ve telefon numarası
            üzerinden ve meslek gruplarına göre yurtiçi ve yurtdışı iş ortakları
            vasıtası ile dijital (google reklamları, sosyal medya reklamları,
            e-posta pazarlama vb.) ya da geleneksel (telefon araması, SMS, vb.)
            pazarlama faaliyetlerinin gerçekleştirilmesi
          </li>
        </ul>
        <p>
          Çerçevesinde PRATİK YÖNET tarafından aktarılacağını, devredileceğini,
          sınıflandırılabileceğini ve Kanun’da sayılan sair şekillerde
          işlenebileceğini bildiririz.
        </p>
        <p>
          Müşterilere daha iyi hizmet verebilmek amacıyla ve Kanun ile ilgili
          mevzuattan kaynaklanan yasal yükümlülük çerçevesinde; iş bu metinde
          sayılanlarla sınırlı olmamak üzere söz konusu amaç ve yasal
          yükümlülüklerini yerine getirebilmeyi sağlayacak kişisel verilerinizi
          (ad, soyadı, cep telefonu numarası, e-posta, meslek, eğitim, üyenin
          tüm alışveriş bilgileri, müşteri işlem bilgileriniz sosyal medya
          hesaplarıyla bağlanılması durumunda üyenin o kanallar aracılığıyla
          paylaşılmasına onay verdiği bilgiler, uygulama üzerindeki gezinme ve
          tıklama bilgileri, uygulamayı ve/veya internet sitesini açtığı
          lokasyon bilgileri, alışverişindeki ürün bilgileri sizlerden talep
          edilebilmekte ve bu verileriniz aşağıdaki amaçlara uygun şekilde
          PRATİK YÖNET’in mobil ya da internet uygulamasında kayıt
          edilebilmektedir.
        </p>
        <p>
          Aşağıda belirtilen amaçlar esas alınarak, ancak bunlarla sınırlı
          olmamak üzere, kişisel verileriniz;
        </p>
        <ul className="rizulOl">
          <li className="rizgizLim">
            PRATİK YÖNET in internet sitesinde Müşteri kaydının açılması,
            kullanıcı adı oluşturulması, deneme hesaplarının oluşturulması ve
            güncellenmesi,
          </li>
          <li className="rizgizLim">
            Online satış veya tele-satış yapılması ve bunu sağlayabilmek için
            PRATİK Yönet in Müşteri ile iletişime geçebilmesi,
          </li>
          <li className="rizgizLim">
            PRATİK YÖNET tarafından Müşteriye ait faturanın (e-fatura, e-arşiv,
            kâğıt fatura) Müşteri’ye gönderiminin sağlanması,
          </li>
          <li className="rizgizLim">
            Deneme hesabı, Ödeme, Sipariş ve Destek Talebi takibinin yapılması,
          </li>
          <li className="rizgizLim">
            Çağrı merkezi üzerinden ve/veya tele pazarlama yoluyla
            gerçekleştirilen aramalar üzerinden kalite ölçümü, ürün ihtiyaç
            analizi, müşteri talep analizi gibi ürün ve/veya hizmetlerin
            geliştirilmesini, düzenlenmesini ve/veya iyileştirilmesini
            sağlayacak faaliyetlerin gerçekleştirilmesi
          </li>
          <li className="rizgizLim">
            Bilgilendirme, ürün tanıtımı yapılması, Müşterilere öneri
            sunulabilmesi, kampanya, promosyon, indirim, teklif, etkinlikler,
            çekilişler, yarışmalar ve fırsat faaliyetleri ve hizmetlerimizle
            ilgili Müşterilerimizin bilgilendirilmesi,
          </li>
          <li className="rizgizLim">
            Satış sonrası PRATİK YÖNET tarafından Müşteriye destek
            verilebilmesi,
          </li>
          <li className="rizgizLim">
            PRATİK YÖNET in, Müşteri memnuniyetini oluşturmak ve/veya arttırmak
            amacıyla iyileştirdiği ve yeni geliştirdiği ürünler için,
            Müşterilerini haberdar edilmesi,
          </li>
          <li className="rizgizLim">
            Müşterilere toplu elektronik ileti yönetiminin sağlanması,
          </li>
          <li className="rizgizLim">
            PRATİK YÖNET ’in internet sitesinin çalışması için gerekli temel
            fonksiyonları gerçekleştirmesi,
          </li>
          <li className="rizgizLim">
            PRATİK YÖNET ’in internet sitesini analiz etmek ve bu sayede
            performansını arttırması,
          </li>
          <li className="rizgizLim">
            PRATİK YÖNET ’in internet sitesinin işlevselliğini artırmak ve
            kullanım kolaylığı sağlaması,
          </li>
          <li className="rizgizLim">
            PRATİK YÖNET ’in internet sitesine yönelik olarak kişiselleştirme,
            hedefleme ve reklamcılık faaliyeti gerçekleştirmesi,
          </li>
          <li className="rizgizLim">
            Mesafeli satış sözleşmesi ve Tüketicinin Korunması Hakkında Kanun’un
            ilgili maddeleri tahtında akdettiğimiz sözleşmelerin koşulları,
            güncel durumu ve güncellemeler ile ilgili müşterilerimiz ile
            iletişime geçmek, gerekli bilgilendirmeleri yapabilmek, akdettiğimiz
            sözleşmeler uyarınca üstlenilen yükümlülüklerin ifa edilmesi,
          </li>
          <li className="rizgizLim">
            Müşteri ile kurulacak sözleşmenin kurulması veya ifasıyla doğrudan
            doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel
            verilerin işlenmesinin gerekli olması,
          </li>
          <li className="rizgizLim">
            PRATİK YÖNET’in hukuki yükümlülüğünü yerine getirebilmesi için
            zorunlu olması,
          </li>
          <li className="rizgizLim">
            Bir hakkın tesisi, kullanılması veya korunması için verinizi
            işlemenin zorunlu olması.
          </li>
          <li className="rizgizLim">
            İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla,
            PRATİK YÖNET’in meşru menfaatleri için veri işlenmesinin zorunlu
            olması gibi diğer nedenlerle,
          </li>
        </ul>
        <p>
          Kanun’un 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları
          ve amaçları dâhilinde belirli, açık ve meşru amaçlar için
          işlenecektir.
        </p>
        <h4>
          <b>Veri Sorumlusu’nun Kimliği ve İletişim Bilgileri</b>
        </h4>
        <p>
          PRATİK YÖNET ’in müşterilerinden elde edilen kişisel veriler
          bakımından Kanun ve ilgili düzenlemeler uyarınca “veri sorumlusu”
          sıfatına haiz olan PRATİK YÖNET’e ait kimlik ve iletişim bilgilerine
          aşağıda yer verilmiştir.
        </p>
        <p>
          Pratik Yönet Bilişim San.ve Tic. Ltd.
          <br />
          Şti Paşa Alanı Mah.113.Sk. No:1/a Balıkesir
          <br />
          Karesi VD.1370309146 Tic. Sicil No:13161
          <br />
          Mersis No:0137030914600015
          <br />
          İNTERNET SİTESİ: https://www.PratikPet.com
          <br />
          İletişim Bilgileri:
          <br />
          Tel:0(532) 446 56 99
          <br />
          E-posta:bilgi@PratikPet.com
        </p>

        <p>
          İşbu Müşteri Açık Rıza Metninde yer alan hususlarla alakalı olarak
          açık rıza vermiş olduğum kişisel verilerimin, yukarıda açıklanmış olan
          amaçlarla işlenmesine ve yukarıda belirtilmiş olan kişilere
          aktarılmasına hiçbir etki altında kalmadan, KVKK Kapsamında Aydınlatma
          Metni ni içeren{" "}
          <a style={{ color: "blue" }} href="/gizlilik">
            Gizlilik Politikası
          </a>
          'ı okuyarak açık rıza veriyorum.
        </p>
      </div>
    </>
  );
}
