import Navbar from "../Navbar/Navbar.js";
import "./app.css";
import { Routes, Route } from "react-router-dom";

import Home from "../Pages/Home/Home.js";
import Features from "../Pages/Features/Features.js";
import Prices from "../Pages/Prices/Prices.js";
import Page404 from "../Pages/Page404/Page404.js";
import Giris from "../Pages/Giris/Giris.js";
import Uyeol from "../Pages/Uyeol/Uyeol.js";
import Gizlilik from "../Pages/Gizlilik/Gizlilik.js";
import Sozlesmel from "../Pages/Sozlesmel/Sozlesmel.js";
import Acikriza from "../Pages/Acikriza/Acikriza.js";
import Uyealindi from "../Pages/Uyeol/Uyealdi.js";
import Iletisim from "../Pages/Iletisim/Iletisim.js";
import Swal from "sweetalert2";

import { useState } from "react";
const jose = require("jose");

const showSwal = (titles) => {
  Swal.fire({
    title: titles,
    text: "Lütfen bekleyiniz...",
    showConfirmButton: false,
    allowOutsideClick: false,
    timer: 0,
    timerProgressBar: true,
    didOpen: () => {
      Swal.showLoading();
    },
  });
};

export default function App() {
  const [drm, setDrm] = useState(0);
  const [isim, setIsim] = useState("");

  const getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  const getPyload = async () => {
    try {
      let jwt = getCookie("azm");
      if (jwt.length > 0) {
        const secret = new TextEncoder().encode(
          process.env.REACT_APP_SCRET_KEY
        );
        const { payload } = await jose.jwtVerify(jwt, secret);

        setDrm(1);
        setIsim(payload.data.kln_kadi);
        document.cookie =
          "azm=" +
          jwt +
          ";phat=/; secure;" +
          (payload.data.bh === "1" ? "max-age=7776000;" : "");
        document.cookie =
          "G45er81dK9d=" +
          payload.data.mst_tbladi +
          ";phat=/; secure; " +
          (payload.data.bh === "1" ? "max-age=7776000;" : "");
        document.cookie =
          "k74F51q9we2=" +
          payload.data.kuki +
          ";phat=/; secure; " +
          (payload.data.bh === "1" ? "max-age=7776000;" : "");

        if (payload.data.kln_ytgid === "1") {
          window.location.replace(
            process.env.REACT_APP_PHPBASEURL + "/07asadmin/genel.php"
          );
        } else if (payload.data.kln_ytgid === "2") {
          window.location.replace(
            process.env.REACT_APP_PHPBASEURL + "/00genel/genel.php"
          );
        }
      }
    } catch (error) {
      setDrm(0);
      setIsim("");
      return false;
    }
  };
  getPyload();

  if (drm === 1) {
    return <div className="container">{showSwal(`Merhaba,  ${isim}`)}</div>;
  } else {
    return (
      <div className="container">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/index.php" element={<Home />} />
          <Route path="/ozellikler" element={<Features />} />
          <Route path="/fiyatlar" element={<Prices />} />
          <Route path="/giris" element={<Giris />} />
          <Route path="/uyeol" element={<Uyeol />} />
          <Route path="/gizlilik" element={<Gizlilik />} />
          <Route path="/sozlesme" element={<Sozlesmel />} />
          <Route path="/acikriza" element={<Acikriza />} />
          <Route path="/uyualindi" element={<Uyealindi />} />
          <Route path="/iletisim" element={<Iletisim />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
        {/* <Footer /> */}
      </div>
    );
  }
}
